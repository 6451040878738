import React, { useState } from 'react';

const OrderStatus = () => {
  // Sample data for orders (you can replace this with your data fetching logic)
  const orders = [
    { id: 1, status: 'Placed Order', date: '2024-09-01', amount: '₹1000' },
    { id: 2, status: 'Uploaded', date: '2024-09-02', amount: '₹1500' },
    { id: 3, status: 'Under Printing', date: '2024-09-03', amount: '₹2000' },
    { id: 4, status: 'Under Packing', date: '2024-09-04', amount: '₹1200' },
    { id: 5, status: 'Dispatched', date: '2024-09-05', amount: '₹1800' },
    { id: 6, status: 'Canceled', date: '2024-09-06', amount: '₹500' },
  ];

  const [filter, setFilter] = useState('All');

  // Filter orders based on selected status
  const filteredOrders = filter === 'All' ? orders : orders.filter(order => order.status === filter);

  return (
    <div className="container mx-auto p-6 min-h-screen min-w-full">
      {/* Page Header */}
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">Order Status</h1>
      </div>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow-xl border border-gray-200 mb-6">
        <label htmlFor="statusFilter" className="block text-sm font-medium text-gray-700 mb-2">Filter by Status</label>
        <select
          id="statusFilter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="All">All</option>
          <option value="Placed Order">Placed Order</option>
          <option value="Uploaded">Uploaded</option>
          <option value="Under Printing">Under Printing</option>
          <option value="Under Packing">Under Packing</option>
          <option value="Dispatched">Dispatched</option>
          <option value="Canceled">Canceled</option>
        </select>
      </div>

      {/* Orders List */}
      <div className="bg-white p-8 rounded-lg shadow-xl border border-gray-200">
        {filteredOrders.length > 0 ? (
          <ul className="space-y-4">
            {filteredOrders.map(order => (
              <li key={order.id} className="p-4 bg-gray-50 rounded-lg shadow-sm border border-gray-200 flex justify-between items-center">
                <div>
                  <h2 className="text-lg font-semibold text-gray-900">Order ID: {order.id}</h2>
                  <p className="text-sm text-gray-600">Status: {order.status}</p>
                  <p className="text-sm text-gray-600">Date: {order.date}</p>
                </div>
                <span className="text-lg font-medium text-gray-900">Amount: {order.amount}</span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No orders found for the selected status.</p>
        )}
      </div>
    </div>
  );
};

export default OrderStatus;
