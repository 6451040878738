import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const CreateOrder = () => {
  const { id } = useParams(); // Get product ID from URL
  const [formData, setFormData] = useState({
    product: '',
    deliveryTime: '',
    firmName: '',
    quantity: '',
    price: '',
    totalPrice: '',
  });

  const [userType, setUserType] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [minimumQuantity, setMinimumQuantity] = useState(0); // Track minimum quantity
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [businessProductFile, setBusinessProductFile] = useState(null);
  const [wallet, setWallet] = useState(0); // Track user's wallet balance
  const [insufficientBalance, setInsufficientBalance] = useState(false); // Track insufficient balance
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

  useEffect(() => {
    // Fetch product details by ID
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`https://api.payasvinimilk.com/api/businessProducts/getBusinessProductById/${id}`);
        const product = response.data;
        const token = localStorage.getItem('token'); // Assuming token is stored in local storage
        if (!token) {
          setError('No token found');
          setLoading(false);
          return;
        }
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.user.userId; // Extract userId from decoded token

        const userResponse = await axios.get(`https://api.payasvinimilk.com/api/distribution/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserDetails(userResponse.data); // Set user details
        // Set the fetched user data (assuming user type is in response.data)
        setUserType(userResponse.data.userType);
        setWallet(userResponse.data.wallet); // Get wallet balance

        // Set product data and minimum quantity as initial quantity
        setFormData({
          ...formData,
          product: product.name,
          deliveryTime: product.shippingTime,
          quantity: product.minimumQuantity,
          price: product.prices[userType], // Price based on user type
        });
        setMinimumQuantity(product.minimumQuantity); // Set minimum quantity
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProductDetails();
  }, [id, userType]);

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;

    // Prevent setting quantity below minimum
    if (id === 'quantity' && value < minimumQuantity) {
      setFormData({
        ...formData,
        [id]: minimumQuantity,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  // Calculate total price based on quantity and price per unit
  useEffect(() => {
    const totalPrice = formData.quantity * formData.price;
    setFormData((prevData) => ({ ...prevData, totalPrice }));
  }, [formData.quantity, formData.price]);

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBusinessProductFile(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const previousBalance = userDetails.wallet; // Fetch the user's wallet balance from the API or state
  const orderAmount = formData.totalPrice; // Total price from the form
  const newBalance = previousBalance - orderAmount;

    // Check if the total price exceeds the wallet balance
    if (formData.totalPrice > wallet) {
      setInsufficientBalance(true);
      setIsSubmitting(false);
      return; // Prevent form submission
    } else {
      setInsufficientBalance(false);
    }

    const orderDetails = new FormData();
    orderDetails.append('productId', id); // Assuming the product ID is in the URL param
    orderDetails.append('userId', userDetails._id); // User ID

    // Append individual user details based on your schema
    orderDetails.append('userDetails[businessName]', userDetails.businessName);
    orderDetails.append('userDetails[contactPerson]', userDetails.contactPerson);
    orderDetails.append('userDetails[mobile]', userDetails.mobile);
    orderDetails.append('userDetails[email]', userDetails.email);
    orderDetails.append('userDetails[pinCode]', userDetails.pinCode);
    orderDetails.append('userDetails[shippingAddress]', userDetails.shippingAddress);
    orderDetails.append('userDetails[city]', userDetails.city);
    orderDetails.append('userDetails', JSON.stringify(userDetails));
    orderDetails.append('totalPrice', formData.totalPrice);
    orderDetails.append('file', businessProductFile); // Append the file directly
    orderDetails.append('quantity', formData.quantity);
    orderDetails.append('firmName', formData.firmName);
    orderDetails.append('orderStatus', 'pending'); // default order status
    orderDetails.append('orderDate', new Date());
    orderDetails.append('walletHistory[amount]', formData.totalPrice);
    orderDetails.append('walletHistory[remark]', 'Order Payment');
    orderDetails.append('walletHistory[receiptId]', `REC-${Date.now()}`);
    orderDetails.append('walletHistory[date]',  new Date());
    orderDetails.append('walletHistory[previousBalance]', userDetails.wallet);
    orderDetails.append('walletHistory[newBalance]', newBalance);

    try {
      const orderResponse = await axios.post('https://api.payasvinimilk.com/api/businessOrders/createBusinessOrder', orderDetails, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure the header is set for file uploads
        },
      });
      // Ensure the order was created successfully and we have the order ID
      const orderId = orderResponse?.data?.BusinessOrder._id;
      if (orderResponse.status === 201) {
        console.log('Order created successfully:', orderResponse.data);
        alert(`Order ${orderId} created successfully`);

        // Update wallet amount after order creation
        const transactionType = 'debit'; // Deducting from wallet
        const walletResponse = await axios.put(`https://api.payasvinimilk.com/api/distribution/${userDetails._id}/wallet`, {
          wallet: formData.totalPrice, // Amount to deduct
          remark: `Order placed with ID: ${orderId}`,
          receiptId: orderId,
          transactionType: transactionType // 'debit'
        });

        if (walletResponse.status === 200) {
          setFormData({ firmName: '' });
          setBusinessProductFile(null);
        }
      }
    } catch (error) {
      console.error('Error submitting order:', error);
      setError('Error submitting the order');
    } finally {
      setIsSubmitting(false); // Re-enable the submit button after submission
    }
  };



  return (
    <div className="container mx-auto p-6 min-h-screen min-w-full">
      {/* Header */}
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Create Order</h1>
      </div>

      {/* Order Form */}
      <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-200">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Product Name */}
            <div>
              <label htmlFor="product" className="block text-sm font-medium text-gray-700">Product</label>
              <input
                id="product"
                type="text"
                value={formData.product}
                readOnly
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none sm:text-sm"
              />
            </div>

            {/* Delivery Time */}
            <div>
              <label htmlFor="deliveryTime" className="block text-sm font-medium text-gray-700">Delivery Time</label>
              <input
                id="deliveryTime"
                type="text"
                value={formData.deliveryTime}
                readOnly
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none sm:text-sm"
              />
            </div>

            {/* Quantity */}
            <div>
              <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                Quantity (Minimum Quantity is {minimumQuantity})
              </label>
              <input
                id="quantity"
                type="number"
                value={formData.quantity}
                onChange={handleChange}
                min={minimumQuantity}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none sm:text-sm"
              />
            </div>

            {/* Price Per Unit */}
            <div>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price Per Unit</label>
              <input
                id="price"
                type="text"
                value={formData.price}
                readOnly
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none sm:text-sm"
              />
            </div>

            {/* Total Price */}
            <div>
              <label htmlFor="totalPrice" className="block text-sm font-medium text-gray-700">Total Price</label>
              <input
                id="totalPrice"
                type="text"
                value={formData.totalPrice}
                readOnly
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none sm:text-sm"
              />
            </div>

            {/* Firm Name */}
            <div>
              <label htmlFor="firmName" className="block text-sm font-medium text-gray-700">Firm Name (जिस नाम से कार्ड छपना है।)</label>
              <input
                id="firmName"
                type="text"
                value={formData.firmName}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none sm:text-sm"
              />
            </div>

            {/* File Upload */}
            <div className="md:col-span-2">
              <label htmlFor="file" className="block text-sm font-medium text-gray-700">Upload Only PDF OR CDR Format</label>
              <input
                type="file"
                onChange={handleFileChange} // Update this line
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none sm:text-sm"
              />
            </div>
          </div>

          {/* Insufficient Balance Message */}
          {insufficientBalance && (
            <div className="text-red-600 font-medium">
              Insufficient Balance in Wallet.
            </div>
          )}

          {/* Buttons */}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={() => setFormData({
                product: '',
                deliveryTime: '',
                firmName: '',
                quantity: '',
                price: '',
                file: null,
              })}
              className="px-6 py-3 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Clear All
            </button>
            <div>
            <button
              type="submit"
              className={`w-full inline-flex justify-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none sm:text-sm ${
                isSubmitting ? 'cursor-not-allowed opacity-50' : ''
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Order'}
            </button>
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrder
