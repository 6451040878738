import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import CreateOrder from './pages/CreateOrder';
import Login from './pages/Login';
import OrderStatus from './pages/OrderStatus';
import Products from './pages/Products';
import Profile from './pages/Profile';
import Wallet from './pages/Wallet';
import AddFunds from './pages/AddFunds';

// A function to check if the user is logged in by checking for a token
const isAuthenticated = () => {
  return localStorage.getItem('token') !== null;
};

// A wrapper for protected routes
const PrivateRoute = ({ element: Element }) => {
  return isAuthenticated() ? <Element /> : <Navigate to="/login" />;
};

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const user = {
    name: 'Rakesh Sharma',
    company: 'SASTTA PRINT SIRSA',
    balance: 14061,
    userId: 20,
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const location = useLocation(); // Get the current location

  // Check if the current path is the login page
  const isLoginPage = location.pathname === '/login';

  return (
    <div className="flex">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="w-full">
        {/* Conditionally render Navbar */}
        {!isLoginPage && <Navbar toggleSidebar={toggleSidebar} user={user} />}
        <main className="">
          <Routes>
            {/* Public Route */}
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route path="/" element={<PrivateRoute element={Home} />} />
            <Route path="/create-order/:id" element={<PrivateRoute element={CreateOrder} />} />
            <Route path="/order-status" element={<PrivateRoute element={OrderStatus} />} />
            <Route path="/products" element={<PrivateRoute element={Products} />} />
            <Route path="/profile" element={<PrivateRoute element={Profile} />} />
            <Route path="/wallet" element={<PrivateRoute element={Wallet} />} />
            <Route path="/add-funds" element={<PrivateRoute element={AddFunds} />} />
          </Routes>
        </main>
        {/* Conditionally render Footer */}
        {!isLoginPage && <Footer />}
      </div>
    </div>
  );
};

const App = () => (
  <Router>
    <Layout />
  </Router>
);

export default App;
