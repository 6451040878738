import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Make sure to install jwt-decode

// API URL (replace with your actual API endpoint)
const API_URL = 'https://api.payasvinimilk.com/api/businessProducts/getAllBusinessProducts';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [userType, setUserType] = useState(null); // State to hold user type
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming token is stored in local storage
        if (!token) {
          setError('No token found');
          setLoading(false);
          return;
        }
        
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.user.userId; // Extract userId from decoded token

        const response = await axios.get(`https://api.payasvinimilk.com/api/distribution/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Set the fetched user data (assuming user type is in response.data)
        setUserType(response.data.userType); // Adjust according to your API response structure
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-6 min-w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {products.map(product => (
          <Link
            key={product._id}
            to={`/create-order/${product._id}`}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <img
              src={product.image}
              alt={product.name}
              className="w-full h-40 object-cover rounded-md mb-4"
            />
            <h2 className="text-xl font-semibold text-gray-800 mb-2">{product.name}</h2>
            
            {/* Display the price only if the user type matches */}
            {userType && product.prices[userType] !== undefined ? (
              <h4 className="text-md font-semibold text-gray-800">
                Price: ₹{product.prices[userType]}
              </h4>
            ) : (
              <h4 className="text-md font-semibold text-gray-800">Price not available for your user type.</h4>
            )}

            <p className="text-gray-600">Dispatch Time: {product.shippingTime}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Products;
