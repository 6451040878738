import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Make sure to install axios if you haven't
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode

const Profile = () => {
  // Initial state for form data
  const [formData, setFormData] = useState({
    businessName: '',
    contactPerson: '',
    country: '',
    state: '',
    city: '',
    pinNumber: '',
    gstNumber: '',
    shippingAddress: '',
    mobile: '',
    email: '',
  });

  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors
  const decodeToken = (token) => {
    if (!token) return null; // Return null if there's no token
    try {
      return jwtDecode(token); // Use jwtDecode to decode the token
    } catch (err) {
      console.error('Error decoding token:', err);
      return null;
    }
  };

  // Fetch user data from the backend API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('Token not found');
        }

        // Decode the token to get user data
        const decodedToken = decodeToken(token);
        
        // Print the decoded token on the console
        console.log('Decoded Token:', decodedToken.user.userId);

        const userIdofauth = decodedToken.user.userId; // Extract userId from decoded token
        console.log("userid" + userIdofauth);
        const response = await axios.get(`https://api.payasvinimilk.com/api/distribution/${userIdofauth}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Set the fetched data to the formData state
        setFormData(response.data); // Adjust according to your API response structure
        setLoading(false);
      } catch (err) {
        console.error('Error fetching profile data:', err);
        setError('Failed to load profile data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Handle change in input fields
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in local storage
      const decodedToken = decodeToken(token);
      const userIdofauth = decodedToken.user.userId; // Extract userId from decoded token
      
      await axios.put(`https://api.payasvinimilk.com/api/distribution/${userIdofauth}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Profile updated successfully', formData);
      alert('Profile updated successfully');
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  // Display loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto min-w-full p-6 min-h-screen">
      {/* Profile Header */}
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">Edit Profile</h1>
      </div>

      {/* Profile Form */}
      <div className="bg-white p-8 rounded-lg shadow-xl border border-gray-200">
        <form className="space-y-8" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Business Name */}
            <div>
              <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Business Name</label>
              <input
                id="businessName"
                type="text"
                value={formData.businessName}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Contact Person */}
            <div>
              <label htmlFor="contactPerson" className="block text-sm font-medium text-gray-700">Contact Person</label>
              <input
                id="contactPerson"
                type="text"
                value={formData.contactPerson}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Select Country */}
            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">Select Country</label>
              <input
                id="country"
                type="text"
                value={formData.country}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Select State */}
            <div>
              <label htmlFor="state" className="block text-sm font-medium text-gray-700">Select State</label>
              <input
                id="state"
                type="text"
                value={formData.state}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Select City */}
            <div>
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">Select City</label>
              <input
                id="city"
                type="text"
                value={formData.city}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Pin Number */}
            <div>
              <label htmlFor="pinNumber" className="block text-sm font-medium text-gray-700">Pin Code</label>
              <input
                id="pinNumber"
                type="text"
                value={formData.pinCode}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* GST Number */}
            <div>
              <label htmlFor="gstNumber" className="block text-sm font-medium text-gray-700">GST Number</label>
              <input
                id="gstNumber"
                type="text"
                value={formData.gstNumber}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Shipping Address */}
            <div className="md:col-span-2">
              <label htmlFor="shippingAddress" className="block text-sm font-medium text-gray-700">Shipping Address</label>
              <textarea
                id="shippingAddress"
                rows="4"
                value={formData.shippingAddress}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Mobile */}
            <div>
              <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">Mobile</label>
              <input
                id="mobile"
                type="text"
                value={formData.mobile}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                id="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          {/* Save Button */}
          <div className="mt-8 flex justify-end">
            <button
              type="submit"
              className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
