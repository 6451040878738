import React, { useEffect, useState } from 'react';
import { FaBars, FaUserCircle } from 'react-icons/fa';
import axios from 'axios'; // Make sure to install axios if you haven't
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import { useNavigate } from 'react-router-dom'; // useNavigate for navigation

const Navbar = ({ toggleSidebar }) => {
  const [user, setUser] = useState(null); // State to hold user data
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors
  const navigate = useNavigate(); // Replaces useHistory for navigation

  const decodeToken = (token) => {
    if (!token) return null; // Return null if there's no token
    try {
      return jwtDecode(token); // Use jwtDecode to decode the token
    } catch (err) {
      console.error('Error decoding token:', err);
      return null;
    }
  };

  // Fetch user data from the backend API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming token is stored in local storage
        const decodedToken = decodeToken(token);
        const userIdofauth = decodedToken.user.userId; // Extract userId from decoded token
        console.log("userid" + userIdofauth);
        const response = await axios.get(`https://api.payasvinimilk.com/api/distribution/${userIdofauth}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Set the fetched user data
        setUser(response.data); // Adjust according to your API response structure
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Logout function
  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      localStorage.removeItem('token'); // Remove token from localStorage
      navigate('/login'); // Redirect to login page or home page
      window.location.reload()
    }
  };

  // Display loading and error states
  if (loading) return <div className="text-white">Loading...</div>;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  return (
    <nav className="bg-gradient-to-r from-gray-800 via-gray-900 to-black p-4 text-white flex justify-between items-center shadow-2xl">
      {/* Sidebar Toggle Button with Brand Name */}
      <div className="flex items-center space-x-4">
        <button className="p-2 bg-gray-700 text-white rounded-full hover:bg-gray-600 transition-all" onClick={toggleSidebar}>
          <FaBars size={24} />
        </button>
        <h1 className="text-xl font-bold tracking-wider uppercase text-white">Sasta Print Business</h1>
      </div>

      {/* User Info */}
      <div className="flex items-center space-x-4">
        <FaUserCircle size={32} className="text-gray-400" />
        <div className="text-right">
          <p className="text-sm font-medium">Welcome Mr. {user.contactPerson}</p>
          <p className="text-xs text-gray-300">{user.businessName}</p>
        </div>
        <div className="text-sm text-right">
          <p>A/C Balance: <span className="font-bold">₹{user.wallet}</span></p>
          <p>USER ID: <span className="font-bold">{user._id}</span></p>
        </div>
        {/* Logout Button */}
        <button 
          onClick={handleLogout}
          className="ml-4 bg-red-600 text-white px-3 py-2 rounded-lg hover:bg-red-500 transition-all"
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
