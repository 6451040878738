import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import Carousel library
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Home = () => {
  const [productCount, setProductCount] = useState(0); // State for product count
  const navigate = useNavigate();

  // Fetch product data when the component mounts
  useEffect(() => {
    const fetchProductCount = async () => {
      try {
        const response = await fetch('https://api.payasvinimilk.com/api/businessProducts/getAllBusinessProducts');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();

        // Check if data is an array and update the product count
        if (Array.isArray(data)) {
          setProductCount(data.length);
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductCount();
  }, []); // Empty dependency array means this runs once on component mount

  // Carousel settings
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Function to handle navigation to products page
  const handlePlaceOrderClick = () => {
    navigate('/products'); // Navigate to the products page
  };

  return (
    <div className="container mx-auto p-6">
      {/* Carousel Component */}
      <div className="mb-8">
        <Slider {...carouselSettings}>
          <div className="flex justify-center items-center h-64 bg-gray-200 rounded-lg">
            <h2 className="text-2xl font-semibold">Carousel Slide 1</h2>
          </div>
          <div className="flex justify-center items-center h-64 bg-gray-200 rounded-lg">
            <h2 className="text-2xl font-semibold">Carousel Slide 2</h2>
          </div>
          {/* Add more slides as needed */}
        </Slider>
      </div>

      {/* Dashboard Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {[
          { 
            title: 'Place Order', 
            count: productCount, 
            description: 'नया ऑर्डर यहाँ पर दें।', 
            icon: 'fas fa-plus-circle', 
            color: 'bg-blue-500',
            onClick: handlePlaceOrderClick // Add onClick handler
          },
          { title: 'Uploaded', count: 0, description: 'ऑर्डर जो आपने दें दिये हैं।', icon: 'fas fa-upload', color: 'bg-green-500' },
          { title: 'Under Printing', count: 0, description: 'ऑर्डर जो छप रहे हैं।', icon: 'fas fa-print', color: 'bg-yellow-500' },
          { title: 'Under Packing', count: 11, description: 'ऑर्डर जो पैक हो रहे हैं।', icon: 'fas fa-box', color: 'bg-orange-500' },
          { title: 'Dispatched', count: 162, description: 'ऑर्डर जो भेजे जा चुके हैं।', icon: 'fas fa-truck', color: 'bg-teal-500' },
          { title: 'Canceled', count: 0, description: 'ऑर्डर जो रद्द कर दिये गये।', icon: 'fas fa-times-circle', color: 'bg-red-500' },
        ].map((card, index) => (
          <div 
            key={index} 
            className={`p-6 rounded-lg shadow-lg ${card.color} text-white flex items-center cursor-pointer`} 
            onClick={card.onClick ? card.onClick : undefined} // Add onClick event
          >
            <i className={`${card.icon} text-3xl mr-4`}></i>
            <div>
              <h3 className="text-xl font-semibold">{card.title}</h3>
              <p className="text-sm">{card.description}</p>
              <p className="text-2xl font-bold mt-2">{card.count}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
