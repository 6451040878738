import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Wallet = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to decode the JWT token
  const decodeToken = (token) => {
    if (!token) return null; // Return null if there's no token
    try {
      return jwtDecode(token); // Use jwtDecode to decode the token
    } catch (err) {
      console.error('Error decoding token:', err);
      return null;
    }
  };

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        // Get the token from local storage
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('Token not found');
        }

        // Decode the token to get user data
        const decodedToken = decodeToken(token);
        
        // Print the decoded token on the console
        console.log('Decoded Token:', decodedToken.user.userId);

        const userIdofauth = decodedToken.user.userId; // Extract userId from decoded token
        console.log("userid" + userIdofauth);

        // Fetch wallet data using userId
        const walletResponse = await fetch(`https://api.payasvinimilk.com/api/distribution/${userIdofauth}`);
        
        if (!walletResponse.ok) {
          throw new Error('Failed to fetch wallet data');
        }

        const walletData = await walletResponse.json();

        // Set state with fetched data
        setWalletBalance(walletData.wallet);
        setTransactions(walletData.transactionHistory);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching wallet data:', err);
        setError('Failed to load wallet data');
        setLoading(false);
      }
    };

    fetchWalletData();
  }, []);

  // Display loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto min-w-full p-6 min-h-screen">
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">Wallet</h1>
        <Link to="/add-funds"> {/* Update this to a Link to AddFunds page */}
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Add Funds
          </button>
        </Link>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-xl border border-gray-200 mb-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Wallet Balance</h2>
        <div className="text-3xl font-bold text-green-600">₹{walletBalance.toFixed(2)}</div>
        <p className="text-gray-600 mt-2">Total available balance</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-xl border border-gray-200">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Transaction History</h2>
        <div className="space-y-4">
          {transactions.map((transaction) => (
            <div
              key={transaction._id} // Use the correct transaction key
              className={`p-4 rounded-lg shadow-sm border ${
                transaction.transactionType === 'credit'
                  ? 'bg-green-50 border-green-200'
                  : 'bg-red-50 border-red-200'
              }`}
            >
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold text-gray-800">
                  {transaction.transactionType === 'credit' ? 'Credit' : 'Debit'}
                </h3>
                <span
                  className={`text-sm font-medium ${
                    transaction.transactionType === 'credit' ? 'text-green-600' : 'text-red-600'
                  }`}
                >
                  ₹{transaction.amount.toFixed(2)} {/* Format as currency */}
                </span>
              </div>

              <div className="text-sm text-gray-600">
                Previous Balance: ₹{transaction.previousBalance.toFixed(2)}
              </div>
              <div className="text-sm text-gray-600">
                New Balance: ₹{transaction.newBalance.toFixed(2)}
              </div>
              <div className="text-sm text-gray-600">{new Date(transaction.date).toLocaleString()}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wallet;
