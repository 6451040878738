import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { FaHome, FaClipboardList, FaSignInAlt, FaTruck, FaBox, FaUser, FaWallet } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const sidebarRef = useRef(null);


  // Close the sidebar when clicking a link
  const handleLinkClick = () => {
    toggleSidebar(false);
  };

  return (
    <aside
      ref={sidebarRef}
      className={`fixed top-0 left-0 h-full bg-gradient-to-b from-gray-900 to-gray-800 text-white transition-transform transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } w-64 z-10 shadow-lg`}
    >
      {/* Close Sidebar button */}
      <div className="flex justify-between items-center p-4 border-b border-gray-700">
        <h2 className="text-lg font-semibold">Menu</h2>
        <button className="text-white hover:text-gray-400" onClick={() => toggleSidebar(false)}>
          ✕ Close
        </button>
      </div>

      <ul className="mt-6 space-y-2">
        <li>
          <Link to="/" onClick={handleLinkClick} className="flex items-center space-x-3 p-4 hover:bg-gray-700 hover:text-blue-400 transition-colors duration-300">
            <FaHome size={20} />
            <span className="text-lg">Home</span>
          </Link>
        </li>
        <li>
          <Link to="/create-order" onClick={handleLinkClick} className="flex items-center space-x-3 p-4 hover:bg-gray-700 hover:text-blue-400 transition-colors duration-300">
            <FaClipboardList size={20} />
            <span className="text-lg">Create Order</span>
          </Link>
        </li>
        <li>
          <Link to="/login" onClick={handleLinkClick} className="flex items-center space-x-3 p-4 hover:bg-gray-700 hover:text-blue-400 transition-colors duration-300">
            <FaSignInAlt size={20} />
            <span className="text-lg">Login</span>
          </Link>
        </li>
        <li>
          <Link to="/order-status" onClick={handleLinkClick} className="flex items-center space-x-3 p-4 hover:bg-gray-700 hover:text-blue-400 transition-colors duration-300">
            <FaTruck size={20} />
            <span className="text-lg">Order Status</span>
          </Link>
        </li>
        <li>
          <Link to="/products" onClick={handleLinkClick} className="flex items-center space-x-3 p-4 hover:bg-gray-700 hover:text-blue-400 transition-colors duration-300">
            <FaBox size={20} />
            <span className="text-lg">Products</span>
          </Link>
        </li>
        <li>
          <Link to="/profile" onClick={handleLinkClick} className="flex items-center space-x-3 p-4 hover:bg-gray-700 hover:text-blue-400 transition-colors duration-300">
            <FaUser size={20} />
            <span className="text-lg">Profile</span>
          </Link>
        </li>
        <li>
          <Link to="/wallet" onClick={handleLinkClick} className="flex items-center space-x-3 p-4 hover:bg-gray-700 hover:text-blue-400 transition-colors duration-300">
            <FaWallet size={20} />
            <span className="text-lg">Wallet</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
