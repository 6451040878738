import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-gray-800 via-gray-900 to-black p-6 text-white  w-full flex justify-center items-center">
      <p className="text-center text-gray-300 text-sm">
        © {new Date().getFullYear()} Developed by <span className="text-blue-400 font-semibold">Soft Coders</span>. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
